@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
body{overflow:hidden;}
.App a{-webkit-tap-highlight-color:  rgba(255, 255, 255, 0) !important; }

.App {
  text-align: center;
  height:100vh;
}

@media screen and (min-width:550px){
  .App {
    text-align: center;
    width: 375px;
    height:807px;
    position: absolute;
    left: 50%;
    top:50%;
    transform:translate(-50%,-50%);
    border:1px solid #ccc;
    cursor: pointer;
  }
  .App *{cursor:pointer}
}

.App-logo {
  height: 150px;
  pointer-events: none;
  margin-top:72px;
}


.App-header.secondary{
 background-image: linear-gradient(127deg, #b60a0a 12%, #7a0000 90%);
}
.App-header.yellow{
  background-image : radial-gradient(circle at 50% 27%, #fff6a6, #f5d500 38%, #deb900 70%);
 }

.App-link {
  color: #61dafb;
}

#menu{}

#menu a{}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
